import React from 'react'

const IcArrowDownBlack = (props) => {
    return (
        <svg {...props} width={16} height={16} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M4 6L8 10L12 6" stroke="#010101" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    )
}

export default IcArrowDownBlack
