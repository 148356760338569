import React, { useEffect, useRef } from 'react'

const EPSILON = 10

const useScrollEnd = ({scrollEleRef, callback, dependencies=[], dependenciesScroll=[], callBackScroll}) => {
    const isRunningCallback = useRef(false)
    const callbackRef = useRef(callback)
    const lastScrollTop = useRef(0)

    useEffect(() => {
        callbackRef.current = callback
    }, dependencies);

    useEffect(() => {
        const scrollEle = scrollEleRef.current;
        if(!scrollEle) return;
        const _handleScroll = () => {
            if(isRunningCallback.current) return;
            // check scroll down
            const valueCheck = scrollEle.scrollTop;
            if(callBackScroll) callBackScroll(valueCheck)
            const isScrollDown = valueCheck > lastScrollTop.current
            lastScrollTop.current = valueCheck <= 0 ? 0 : valueCheck
            if(!isScrollDown) {
                return;
            }
            // console.log(valueCheck, "-", lastScrollTop.current)

            // check to end of scroll
            const { scrollHeight, scrollTop, clientHeight } = scrollEle
            const condActiveCallback = scrollHeight - scrollTop - clientHeight <= EPSILON
            if(!condActiveCallback) return;
            isRunningCallback.current = true
            callbackRef.current(() => { isRunningCallback.current = false })
        }
        scrollEle.addEventListener('scroll', _handleScroll)
        return () => {
            scrollEle.removeEventListener('scroll', _handleScroll)
        }
    }, dependenciesScroll);
}

export default useScrollEnd
