import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

// css
import { DeltailBlockContainer } from '../../../mainStyles'

// component
import Portal from '../../../control/Portal'
import PopupPostCheck from '../../../Popup/PopupPostCheck'
import PopupConfirmPostCheck from '../../../Popup/PopupConfirmPostCheck'
import Button from '../../../control/Button'
import { apiGetAvatar } from '../../../../constant/host'

//helper
import { convertDateTime } from '../../../../helper/convertDateTime'
import { checkStatus } from '../../../../helper/checkStatus'
import numberFormatToThousand from '../../../../helper/numberFormatToThousand'

//images
import IcCheckUserWhite from '../../../../assets/images/IcCheckUserWhite'

// action
import { countTransaction } from '../../../../redux/transaction/action'



const POSTCHECK = 'POSTCHECK'
const CONFIRMPOSTCHECK = 'CONFIRMPOSTCHECK'

export default function DetailBlock({data}) {
    
    const [keyShowForm, setKeyShowForm] = useState('')
    const [payload,setPayload] = useState({})
    const [isErrImg, setIsErrImg] = useState(false)

    const dispatch = useDispatch()
    const {customerId} = useParams()


    const { totalListTransaction } = useSelector(state => ({
        totalListTransaction: state.transaction.totalListTransaction,
    }))

    const _handleClickBtn = () => {
        setKeyShowForm(POSTCHECK)
    }

    const _handleSubmit = (id, reviewStatus, reviewDesc) => {
        setKeyShowForm(CONFIRMPOSTCHECK)
        setPayload({id: id, reviewStatus: reviewStatus, reviewDesc: reviewDesc})
    }

    const callbackSuccess = () => {}
    const callbackError = () => {}

    useEffect(() => {
        dispatch(countTransaction(customerId, callbackSuccess, callbackError))
        setIsErrImg(false)
    }, [])

    return (
        <>
            <DeltailBlockContainer>
                <div className='detail-container'>
                    <div className='face-block'>
                        <img src={apiGetAvatar(data?.avatarId)} alt="imgTesst" onError={() => setIsErrImg(true)}/>    
                    </div>
                    <div className='info-block post-check-info'>
                        <div className='info-block-item'>
                            <p className='p-value' title={`${data?.customerId}`}>{data?.customerId}</p>
                            <p className='p-title'>Số CIF</p>
                        </div>
                        <div className='info-block-item'>
                            <p className='p-value'>{totalListTransaction > 0 ? numberFormatToThousand(totalListTransaction) : '0'}</p>
                            <p className='p-title'>Số lượng giao dịch</p>
                        </div>
                        {
                            data.reviewStatus !== 0 ?
                            <div className='info-block-item'>
                                <p className='p-value' title={`${data?.reviewer}`}>{data?.reviewer}</p>
                                <p className='p-title'>Nhân viên hậu kiểm</p>
                            </div>
                            :
                            <div></div>
                        }
                        <div className='info-block-item'>
                            <p className='p-value'>{data?.createAt ? convertDateTime(data?.createAt, "DD/MM/YYYY - HH:mm") : "-"}</p>
                            <p className='p-title'>Thời gian đăng ký</p>
                        </div>
                        <div className='info-block-item'>
                            <p className={checkStatus(data?.reviewStatus)?.classNameStatus}>{checkStatus(data?.reviewStatus)?.textStatus}</p>
                            <p className='p-title'>Trạng thái </p>
                        </div>
                        {
                            data.reviewStatus !== 0 ?
                            <div className='info-block-item'>
                                <p className='p-value'>{data?.reviewAt ? convertDateTime(data?.reviewAt, "DD/MM/YYYY - HH:mm") : "-"}</p>
                                <p className='p-title'>Thời gian hậu kiểm</p>
                            </div>
                            :
                            <div></div>
                        }
                        {
                            (data.reviewStatus === 2 || data?.reviewStatus === 3) ?
                            <div className='info-block-item cause'>
                                <p className='p-value'>{data?.reviewDesc ? data?.reviewDesc : "-"}</p>
                                <p className='p-title'>Lý do thất bại</p>
                            </div>
                            :
                            <div></div>
                        }
                    </div>
                </div>
                <div className='btn-container'>
                    {
                        data?.reviewStatus === 0 && 
                        <Button
                            className='btn-post-check'
                            text="Hậu kiểm"
                            iconBtn={<IcCheckUserWhite/>}
                            onClick={_handleClickBtn}
                        />
                    }
                </div>
            </DeltailBlockContainer>
            {
                keyShowForm === POSTCHECK && 
                <Portal>
                    <PopupPostCheck 
                        onClickIconClose={()=>setKeyShowForm('')} 
                        _handleSubmit={_handleSubmit}
                        data={data}
                        />
                </Portal>
            }
            {
                keyShowForm === CONFIRMPOSTCHECK && 
                <Portal>
                    <PopupConfirmPostCheck 
                        onClickIconClose={()=>setKeyShowForm('')}
                        payload = {payload} 
                        data={data}
                    />
                </Portal>
            }
        </>
    )
}
