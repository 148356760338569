import styled from 'styled-components'

import { WrapPageItem, TableHeader, TableContent } from '../../mainStyles'

const WrapPostCheck = styled(WrapPageItem)`
       
`;

const TableHeaderPostCheck = styled(TableHeader)`
    grid-template-columns: 
    minmax(80px,116px) 
    minmax(100px,150px) 
    minmax(110px,1fr) 
    minmax(110px,180px) 110px;
`;

const TableContentPostCheck = styled(TableContent)`
    
`;

export {
    WrapPostCheck,
    TableHeaderPostCheck,
    TableContentPostCheck
}