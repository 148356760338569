import React from "react";

const IcBtnDownloadGrey = (props) => {
  return (
    <svg
      {...props}
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.25 8.75V11.0833C12.25 11.3928 12.1271 11.6895 11.9083 11.9083C11.6895 12.1271 11.3928 12.25 11.0833 12.25H2.91667C2.60725 12.25 2.3105 12.1271 2.09171 11.9083C1.87292 11.6895 1.75 11.3928 1.75 11.0833V8.75"
        stroke="#B7B7B7"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4.08325 5.83301L6.99992 8.74967L9.91659 5.83301"
        stroke="#B7B7B7"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7 8.75V1.75"
        stroke="#B7B7B7"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default IcBtnDownloadGrey;
