import React from 'react'

export default function IcTransactionHistoryActive(props) {
  return (
    <svg {...props} width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M2.5 4.49805V10.498H8.5" stroke="#212123" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M5.01012 15.4981C5.66015 17.338 6.89057 18.9168 8.51596 19.9966C10.1414 21.0764 12.0737 21.5986 14.0217 21.4846C15.9697 21.3707 17.828 20.6267 19.3164 19.3647C20.8048 18.1028 21.8427 16.3913 22.2738 14.4881C22.7048 12.585 22.5056 10.5932 21.7063 8.81313C20.9069 7.03301 19.5506 5.56092 17.8418 4.6187C16.133 3.67648 14.1642 3.31516 12.2322 3.5892C10.3001 3.86325 8.50951 4.7578 7.13012 6.13806L2.50012 10.4981" stroke="#212123" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M13.5 9.49805V13.998L16.5 15.498" stroke="#008C44" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>

  )
}
