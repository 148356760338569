import styled from "styled-components";

const InputWrapper = styled.div`
    margin-bottom: 20px;
    height: ${props => props.heightInput || "40px"};
    .wrap-input{
        height: 100%;
        position: relative;
        .icon-left{
            position: absolute;
            left: 10px;
            top: 10px;
        }
        input{
            font-family: "Manrope";
            height: 100%;
            width: 100%;
            margin-left: 50px;
            padding: 0;
            margin: 0;
            box-sizing: border-box;
            padding-left: 40px;
            padding-right: 10px;
            border: 0.5px solid #B7B7B7;
            border-radius: 4px;
            color: #313131;
            letter-spacing: -0.01em;
            font-size: 13px;
            line-height: 18px;

            &:disabled {
                background: #F7F7F7;
                font-weight: 600;
                font-size: 14px;
                line-height: 19px;
                color: #191919;
            }

            &:focus{
                outline: none;
                border: 0.5px solid #212123;
            }
            &::placeholder{
                color: #828282;
            }
        }
        & > .active-input{
            border: 0.5px solid #212123;
        }
        & > .error-input{
            border: 0.5px solid #D62A00;
        }
        .notice{
            width: 3px;
            height: 3px;
            border-radius: 50%;
            position: absolute;
            top: 47%;
            left: 44%;
            background-color: #D62A00;
        }
    }
    .error{
        color: #D62A00;
        font-family: "Manrope";
        font-style: normal;
        font-weight: normal;
        font-size: 13px;
        line-height: 15px;
        padding: 2px 0 0 15px;
        float: left;
    }
`

export {InputWrapper};