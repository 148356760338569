import React from "react";
const LogoLogin = (props) => {
  return (
    <svg
      {...props}
      width={187}
      height={50}
      viewBox="0 0 187 50"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M83.9552 38.9122C77.1059 38.9122 71.593 32.7311 71.593 25.1021C71.593 17.4732 77.1616 11.2921 83.9552 11.2921C90.7489 11.2921 96.3174 17.4732 96.3174 25.1021C96.3174 32.7311 90.8045 38.9122 83.9552 38.9122ZM83.9552 3.44043C70.9248 3.44043 60.3445 13.1854 60.3445 25.1021C60.3445 37.0746 70.9248 46.7639 83.9552 46.7639C96.9856 46.7639 107.566 37.0189 107.566 25.1021C107.566 13.1297 96.9856 3.44043 83.9552 3.44043Z"
        fill="#008C44"
      />
      <path
        d="M167.428 10.8462C172.551 10.8462 174.277 11.9043 174.277 15.7466C174.277 19.032 172.273 20.3685 167.595 20.3685H164.811L161.971 28.1088H167.929C173.052 28.1088 175.725 29.7794 175.725 33.733C175.725 37.7981 172.829 39.3573 167.706 39.3573H160.857V10.9019L167.428 10.8462ZM186.862 33.7887C186.807 32.1738 186.417 30.6703 185.693 29.2782C184.969 27.8304 183.911 26.6053 182.463 25.6029C181.739 25.0461 180.848 24.6006 179.902 24.2665C181.461 23.4869 182.686 22.4845 183.577 21.2038C184.913 19.3104 185.526 17.083 185.414 14.5215C185.247 11.4588 184.189 9.00861 182.129 7.17098C180.124 5.38904 177.062 4.44238 173.108 4.44238H149.831V45.7054H170.212C173.776 45.7054 176.895 45.26 179.345 44.369C181.906 43.4223 183.855 42.0302 185.136 40.2482C186.417 38.4663 186.974 36.2946 186.862 33.7887Z"
        fill="#008C44"
      />
      <path
        d="M145.543 35.6267C142.926 37.7428 139.529 38.9679 135.854 38.9679C127.613 38.9679 120.93 32.7311 120.93 25.1021C120.93 17.4175 127.613 11.2364 135.854 11.2364C139.585 11.2364 142.982 12.5172 145.543 14.5776V6.16902C142.091 4.44277 138.082 3.44043 133.794 3.44043C120.485 3.44043 109.682 13.1297 109.682 25.1021C109.682 37.0746 120.485 46.7639 133.794 46.7639C138.082 46.7639 142.091 45.7615 145.543 44.0353V35.6267Z"
        fill="#008C44"
      />
      <path
        d="M0.0968182 27.8002C0.758726 33.7064 3.45727 39.0017 7.47963 42.9222C7.02139 41.7002 6.86865 40.4273 7.17414 39.1544C7.93788 35.6412 11.5529 33.1464 16.5936 32.4335V15.3767C3.50819 13.1364 -0.717837 21.4357 0.0968182 27.8002Z"
        fill="#E39717"
      />
      <path
        d="M49.8417 22.2002C49.1798 16.2939 46.4813 10.9987 42.4589 7.07812C42.9172 8.30011 43.0699 9.573 42.7644 10.8459C42.0007 14.3591 38.3857 16.854 33.345 17.5668V34.6236C46.4304 36.813 50.6564 28.5647 49.8417 22.2002Z"
        fill="#E39717"
      />
      <path
        d="M0.555176 19.4501C3.96655 14.8168 10.0765 12.1182 21.3798 15.275C41.1861 20.8758 47.5506 7.8922 36.2473 2.64786C32.8359 0.916717 29.0172 0.000231592 24.9948 0.000231592C13.0296 -0.0506844 3.10097 8.29953 0.555176 19.4501Z"
        fill="#E39717"
      />
      <path
        d="M49.3835 30.5498C45.9721 35.1832 39.8622 37.8817 28.5589 34.7249C8.75259 29.1242 2.38809 42.1077 13.6914 47.3521C17.0519 49.0832 20.9215 49.9997 24.9439 49.9997C36.8582 50.0506 46.8377 41.7004 49.3835 30.5498Z"
        fill="#E39717"
      />
    </svg>
  );
};

export default LogoLogin;
