import styled from 'styled-components'

const WrapPopupConfirmPostCheck = styled.div`
    width: 352px;
    padding: 24px;
    background:#FFFFFF;
    position: relative;
    box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    font-family: Manrope;
    font-style: normal;
    p{
        font-weight: normal;
        font-size: 15px;
        line-height: 20px;
        color: #828282;
    }
    .title{
        font-weight: 600;
        font-size: 20px;
        line-height: 27px;
        margin-bottom: 12px;
        color: #212123;
        letter-spacing: -0.02em;
    }
    .sub-title{
        font-weight: normal;
        font-size: 13px;
        line-height: 20px;
        color: #828282;
    }
    .icon-close{
        top: 24px;
        right: 24px;
        position: absolute;
        width: 24px;
        height: 24px;
        cursor: pointer;
    }
    .sub-title{
        font-weight: normal;
        font-size: 15px;
        line-height: 20px;
        margin-bottom: 24px;
        color: #828282;
    }
    .span-bold{
        font-weight: 700;
        color: #212123;
    }
    .btn-container{
        width: 100%;
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 20px;
        font-weight: 600;
        font-size: 13px;
        line-height: 18px;
        .btn-cancel, .btn-submit{
            width: 100%;
            height: 38px;
            border: 1px solid #008C44;
        }
        .btn-cancel{
            background: #FFFFFF;
            color: #008C44;
        }
        .btn-submit{
            background: #008C44;
            color: #FFFFFF;
        }
    }
`;

export {
    WrapPopupConfirmPostCheck
}