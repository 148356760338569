import React from 'react'

const IcFaceOTP = (props) => {
    return (
        <svg {...props} width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle cx="16" cy="16" r="16" fill="#E3F9EE"/>
        <path fillRule="evenodd" d="M9.26172 12.6162V10.9316C9.26172 10.0108 10.0109 9.26172 10.9316 9.26172H12.6162C12.8265 9.26172 12.9971 9.0912 12.9971 8.88086C12.9971 8.67052 12.8265 8.5 12.6162 8.5H10.9316C9.59084 8.5 8.5 9.59083 8.5 10.9316V12.6162C8.5 12.8265 8.67052 12.9971 8.88086 12.9971C9.0912 12.9971 9.26172 12.8265 9.26172 12.6162ZM12.6162 22.7382C12.8265 22.7382 12.9971 22.9088 12.9971 23.1191C12.9971 23.3294 12.8265 23.5 12.6162 23.5H10.9316C9.59084 23.5 8.5 22.4091 8.5 21.0683V19.3837C8.5 19.1734 8.67052 19.0029 8.88086 19.0029C9.0912 19.0029 9.26172 19.1734 9.26172 19.3837V21.0683C9.26172 21.9891 10.0109 22.7382 10.9316 22.7382H12.6162ZM23.5 19.3837V21.0683C23.5 22.4091 22.4092 23.5 21.0684 23.5H19.3838C19.1735 23.5 19.0029 23.3294 19.0029 23.1191C19.0029 22.9088 19.1735 22.7382 19.3838 22.7382H21.0684C21.9891 22.7382 22.7383 21.9891 22.7383 21.0683V19.3837C22.7383 19.1734 22.9088 19.0029 23.1191 19.0029C23.3295 19.0029 23.5 19.1734 23.5 19.3837ZM23.5 10.9316V12.6162C23.5 12.8265 23.3295 12.9971 23.1191 12.9971C22.9088 12.9971 22.7383 12.8265 22.7383 12.6162V10.9316C22.7383 10.0108 21.9891 9.26172 21.0684 9.26172H19.3838C19.1735 9.26172 19.0029 9.0912 19.0029 8.88086C19.0029 8.67052 19.1735 8.5 19.3838 8.5H21.0684C22.4092 8.5 23.5 9.59083 23.5 10.9316Z" fill="#008C44"/>
        <path fillRule="evenodd" d="M18.6285 19.5864C18.7889 19.4382 18.7989 19.188 18.6506 19.0275C18.5024 18.8671 18.2522 18.8572 18.0918 19.0054C17.5208 19.5328 16.778 19.8232 16.0001 19.8232C15.2223 19.8232 14.4795 19.5328 13.9085 19.0054C13.748 18.8572 13.4978 18.8671 13.3496 19.0275C13.2014 19.188 13.2113 19.4382 13.3718 19.5864C14.0893 20.2492 15.0227 20.6142 16.0001 20.6142C16.9775 20.6142 17.9109 20.2492 18.6285 19.5864Z" fill="#008C44"/>
        <path fillRule="evenodd" d="M16.7915 16.9678V14.1553C16.7915 13.9368 16.6144 13.7598 16.396 13.7598C16.1776 13.7598 16.0005 13.9368 16.0005 14.1553V16.9678C16.0005 17.0889 15.9019 17.1875 15.7808 17.1875H15.4585C15.2401 17.1875 15.063 17.3646 15.063 17.583C15.063 17.8014 15.2401 17.9785 15.4585 17.9785H15.7808C16.3381 17.9785 16.7915 17.5251 16.7915 16.9678Z" fill="#008C44"/>
        <path fillRule="evenodd" d="M19.5156 15.2612V14.1333C19.5156 13.927 19.3484 13.7598 19.1421 13.7598C18.9358 13.7598 18.7686 13.927 18.7686 14.1333V15.2612C18.7686 15.4675 18.9358 15.6348 19.1421 15.6348C19.3484 15.6348 19.5156 15.4675 19.5156 15.2612Z" fill="#008C44"/>
        <path fillRule="evenodd" d="M12.9453 15.6348C12.739 15.6348 12.5718 15.4675 12.5718 15.2612V14.1333C12.5718 13.927 12.739 13.7598 12.9453 13.7598C13.1516 13.7598 13.3188 13.927 13.3188 14.1333V15.2612C13.3188 15.4675 13.1516 15.6348 12.9453 15.6348Z" fill="#008C44"/>
        </svg>

    )
}

export default IcFaceOTP
