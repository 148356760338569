import { OCB_ADMIN_LOGIN } from "../constant/keys"

const checkSessionLogin = (loginReducer = {uuid: "", token: ""}) => {
    const dataStore = localStorage.getItem(OCB_ADMIN_LOGIN)
    
    if(!dataStore) return false
    
    if(!loginReducer) return false

    let loginStore = JSON.parse(dataStore)
    if(loginStore.uuid !== loginReducer.uuid || 
        loginStore.token !== loginReducer.token){
        return false
    }
    else{
        if(loginStore.sessionKey !== loginReducer.sessionKey) return false
    }
    return true
}

export default checkSessionLogin