import React from "react";

const IcInputLock = (props) => {
  return (
    <svg
      {...props}
      width={20}
      height={20}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.8333 9.16699H4.16667C3.24619 9.16699 2.5 9.91318 2.5 10.8337V16.667C2.5 17.5875 3.24619 18.3337 4.16667 18.3337H15.8333C16.7538 18.3337 17.5 17.5875 17.5 16.667V10.8337C17.5 9.91318 16.7538 9.16699 15.8333 9.16699Z"
        stroke="#B7B7B7"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.83325 9.16699V5.83366C5.83325 4.72859 6.27224 3.66878 7.05364 2.88738C7.83504 2.10598 8.89485 1.66699 9.99992 1.66699C11.105 1.66699 12.1648 2.10598 12.9462 2.88738C13.7276 3.66878 14.1666 4.72859 14.1666 5.83366V9.16699"
        stroke="#B7B7B7"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
export default IcInputLock;
