import React from 'react'

const IcAvatarDefault = (props) => {
    return (
        <svg {...props} width={36} height={36} viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx={18} cy={18} r={18} fill="#F3F8FC" />
            <path d="M24 24.75V23.25C24 22.4544 23.6629 21.6913 23.0627 21.1287C22.4626 20.5661 21.6487 20.25 20.8 20.25H15.2C14.3513 20.25 13.5374 20.5661 12.9373 21.1287C12.3371 21.6913 12 22.4544 12 23.25V24.75" stroke="#92A8BD" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M18 17.25C19.6569 17.25 21 15.9069 21 14.25C21 12.5931 19.6569 11.25 18 11.25C16.3431 11.25 15 12.5931 15 14.25C15 15.9069 16.3431 17.25 18 17.25Z" stroke="#92A8BD" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    )
}

export default IcAvatarDefault
