import styled from 'styled-components'

const WrapSideMenu = styled.div`
    background: #FFFFFF;
    box-shadow: inset -1px 0px 0px rgba(146, 168, 189, 0.25);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding: 57px 0 19px 0 ;
    .wrap-menu{
        width: 100%;
        display: flex;
        flex-direction: column;
        .link-item{
            text-decoration: none;
            width: 100%;
            height: 54px;
            margin-bottom: 25px;
            box-sizing: border-box;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            border-left: 5px solid transparent;
            svg{
                width: 24px;
                height: 24px;
            }
            .icon{
                display: block;
            }
            .icon.active{
                display: none;
            }
            p{
                color: #313131;
                font-size: 11px;
                line-height: 11px;
                letter-spacing: -0.02em;
                margin: 2px 0 0 0;
            }
            &.selected{
                border-left: 5px solid #275E46;
                /* box-sizing: border-box; */
                .icon.active{
                    display: block;
                }
                .icon{
                    display: none;
                }
            }

        }
    }
`;

export {
    WrapSideMenu
}