import styled from 'styled-components'

import { WrapPageItem, TableHeader, TableContent } from '../../mainStyles'

const WrapPostCheck = styled(WrapPageItem)`
       
`;

const TableHeaderPostCheck = styled(TableHeader)`
    grid-template-columns: 
        minmax(80px, 116px)
        minmax(100px, 120px) 
        minmax(110px, 150px)
        minmax(130px, 180px) 
        minmax(150px, 1fr) 
        minmax(150px, 1fr)
        160px;
`;

const TableContentPostCheck = styled(TableContent)`
    
`;

export {
    WrapPostCheck,
    TableHeaderPostCheck,
    TableContentPostCheck
}