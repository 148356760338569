import styled from 'styled-components'

import { WrapPageItem } from '../../mainStyles'

const WrapHistoryDetail = styled(WrapPageItem)`
   .detail-transaction-block{
        background: rgba(227, 249, 238, 0.5);
        border-radius: unset;
        box-shadow: unset;
   }
   .title{
       margin-bottom: 16px;
   }
`;

export {
    WrapHistoryDetail
}