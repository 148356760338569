import styled from 'styled-components'

import { WrapPageItem, TableHeader, TableContent } from '../../mainStyles'

const WrapCustomerDetail = styled(WrapPageItem)`
    
    .title{
        font-family: Manrope;
        font-style: normal;
        font-weight: bold;
        font-size: 15px;
        line-height: 20px;
        color: #212123;
        margin: 0 0 16px 0;

    }
`;

const TableHeaderCustomerHistory = styled(TableHeader)`
    grid-template-columns: 
        minmax(80px, 116px)
        minmax(100px, 120px) 
        minmax(110px, 150px)
        minmax(150px, 380px) 
        minmax(130px, 180px) 
        // minmax(150px, 1fr)
        141px;
    
`;

const TableContentCustomerHistory = styled(TableContent)`
    
`;

export {
    WrapCustomerDetail,
    TableHeaderCustomerHistory,
    TableContentCustomerHistory
}