import * as types from './types'

export const setScroll = ({key = "", scrollTop = 0}) => dispatch => {
    dispatch({
        type: types.SET_SCROLL,
        payload: {
            key,
            scrollTop
        }
    })
}

export const clearScroll = ({key = ""}) => dispatch => {
    dispatch({
        type: types.CLEAR_SCROLL,
        payload: {
            key,
        }
    })
}
