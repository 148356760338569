import styled from "styled-components";

const ButtonWrapper = styled.button`
    pointer-events: ${props => props.isDisable ? "none" : "auto"};
    justify-content: center;
    align-items: center;
    display: flex;
    border-radius: 4px;
    position: relative;
    padding: 8px 12px;
    background: #A31E23;
    cursor: pointer;
    border: 1px solid;
    outline: none;
    color: white;
    font-family: "Manrope";
    font-weight: 600;
    font-size: 13px;
    line-height: 18px;
    letter-spacing: -0.01em;
    &.disable{
        background: #B7B7B7;
    }
    svg{
        margin-right: 5px;
    }
`

export {ButtonWrapper};