import React from "react";

const IcInputEye = (props) => {
  return (
    <svg {...props} width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M8.25016 3.53303C8.82377 3.39876 9.41105 3.33164 10.0002 3.33303C15.8335 3.33303 19.1668 9.99969 19.1668 9.99969C18.661 10.946 18.0577 11.837 17.3668 12.658M11.7668 11.7664C11.538 12.012 11.262 12.209 10.9553 12.3456C10.6486 12.4823 10.3176 12.5557 9.9819 12.5617C9.64623 12.5676 9.3128 12.5058 9.0015 12.3801C8.69021 12.2544 8.40743 12.0672 8.17003 11.8298C7.93264 11.5924 7.74549 11.3096 7.61975 10.9984C7.49402 10.6871 7.43227 10.3536 7.43819 10.018C7.44411 9.68228 7.51759 9.35123 7.65423 9.04457C7.79087 8.7379 7.98787 8.4619 8.2335 8.23303M14.9502 14.9497C13.5257 16.0355 11.7911 16.6371 10.0002 16.6664C4.16683 16.6664 0.833496 9.99969 0.833496 9.99969C1.87007 8.06794 3.30778 6.3802 5.05016 5.0497L14.9502 14.9497Z" stroke="#B7B7B7" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M0.833496 0.833008L19.1668 19.1663" stroke="#B7B7B7" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>

  );
};
export default IcInputEye;
