import React from 'react'

const IcInputLockActive = (props) => {
  return (
    <svg {...props} width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M15.8333 9.16699H4.16667C3.24619 9.16699 2.5 9.91318 2.5 10.8337V16.667C2.5 17.5875 3.24619 18.3337 4.16667 18.3337H15.8333C16.7538 18.3337 17.5 17.5875 17.5 16.667V10.8337C17.5 9.91318 16.7538 9.16699 15.8333 9.16699Z" stroke="#212123" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M5.8335 9.16699V5.83366C5.8335 4.72859 6.27248 3.66878 7.05388 2.88738C7.83529 2.10598 8.89509 1.66699 10.0002 1.66699C11.1052 1.66699 12.165 2.10598 12.9464 2.88738C13.7278 3.66878 14.1668 4.72859 14.1668 5.83366V9.16699" stroke="#212123" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>

  )
}
export default IcInputLockActive
