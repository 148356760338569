import React, { useEffect } from 'react'

// component
import IcCheckedSquareError from '../../../../assets/images/IcCheckedSquareEror'
import IcCheckedSquare from '../../../../assets/images/IcCheckedSquare';

// styles
import { TIME_DELAY_CLOSE, toastTypes, WrapToast } from './index.styles'

const Toast = ({ type, onClose, children }) => {

    useEffect(() => {
        const timer = setTimeout(() => {
            onClose()
        }, TIME_DELAY_CLOSE)
        return () => {
            clearTimeout(timer)
        }
    }, []);

    const iconToast = type === toastTypes.SUCCESS ? <IcCheckedSquare /> : <IcCheckedSquareError />

    return (
        <WrapToast type={type}>
            {iconToast}
            <span>{children}</span>
        </WrapToast>
    )
}

export default Toast
