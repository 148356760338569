import React, { useRef } from 'react'

//images
import IcCheckBlue from '../../../assets/images/IcCheckBlue'

// component


// hook
// import useScrollEnd from '../../../../../hook/useScrollEnd'

// styles
import { WrapDropdownList } from './index.styles'

const DropdownList =    ({ 
    isShowCheckStatus={isShow: false, isSingleChoice: false, isCheckMark:true}, 
    listItem, 
    valueSelected, 
    setValueSelect, 
    alignTop, 
    className, 
    maxItemShow, 
    handleScrollEnd }) => {
    const eleRef = useRef()

    // useScrollEnd(eleRef, (callback) => {
    //     if(!maxItemShow) return;
    //     if(!handleScrollEnd) return;
    //     handleScrollEnd(callback)
    // }, [handleScrollEnd])
    
    return (
        <WrapDropdownList ref={eleRef} alignTop={alignTop} className={className} maxItemShow={maxItemShow}>
            {
                listItem.map((item) => {
                    const { text, key } = item
                    return (
                        <div className={valueSelected?.key === key ? 'item-checked' : ''} key={key} onClick={() => setValueSelect(item)}>
                            
                            <span title={text}>{text}</span>
                            {
                                isShowCheckStatus?.isShow && isShowCheckStatus?.isCheckMark && 
                                valueSelected?.key === key && <IcCheckBlue className='ic-check-mark'/>
                            }
                        </div>
                    )
                })
            }
        </WrapDropdownList>
    )
}

export default DropdownList
