import React from 'react'
import { useSelector } from 'react-redux'
import { Outlet } from 'react-router-dom'
import { WrapMain } from './index.styles'
import Header from './Header'
import SideMenu from './SideMenu'
import Loading from './Loading'
import Notify from './Notify'

const Main = () => {
    const { isLoading } = useSelector(state => ({
        isLoading: state.loading.isLoading
    }))

    return (
        <WrapMain>
            <Header />
            <SideMenu />
            <Outlet />
            <Notify />
            { isLoading && <Loading /> }
        </WrapMain>
    )
}

export default Main