import React from "react";

const IcLogout = (props) => {
  return (
    <svg
      {...props}
      width={28}
      height={28}
      viewBox="0 0 28 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx={14} cy={14} r={14} fill="white" />
      <path
        d="M17 17.75L20.75 14L17 10.25"
        stroke="#B7B7B7"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M20.75 14H11.75"
        stroke="#B7B7B7"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.75 20.75H8.75C8.35218 20.75 7.97064 20.592 7.68934 20.3107C7.40804 20.0294 7.25 19.6478 7.25 19.25V8.75C7.25 8.35218 7.40804 7.97064 7.68934 7.68934C7.97064 7.40804 8.35218 7.25 8.75 7.25H11.75"
        stroke="#B7B7B7"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
export default IcLogout;
