import React from "react";

const IcFlagBlue = (props) => {
  return (
    <svg
      {...props}
      width={20}
      height={20}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.33325 18.3333V12.5"
        stroke="#008C44"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M3.33325 12.5003C3.33325 12.5003 4.16659 11.667 6.66658 11.667C9.16658 11.667 10.8333 13.3337 13.3333 13.3337C15.8333 13.3337 16.6666 12.5003 16.6666 12.5003V2.50033C16.6666 2.50033 15.8333 3.33366 13.3333 3.33366C10.8333 3.33366 9.16658 1.66699 6.66658 1.66699C4.16659 1.66699 3.33325 2.50033 3.33325 2.50033V12.5003Z"
        stroke="#008C44"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default IcFlagBlue;
