import React, { useState, useRef, useEffect } from 'react'

//style
import { InputWrapper } from './index.styles'

//image
import IcInputEyeActive from '../../../assets/images/IcInputEyeActive'
import IcInputEye from '../../../assets/images/IcInputEye'
import IcInputLock from '../../../assets/images/IcInputLock'
import IcInputLockActive from '../../../assets/images/IcInputLockActive'
// import IcInputLockError from '../../../assets/images/IcInputLockError'
// import IcEditBlack from '../../../assets/images/IcEditBlack'

const PWD = "PWD"
const PWD_ACTIVE = "PWD_ACTIVE"
const PWD_ERROR = "PWD_ERROR"

const iconPassword = {
    icon: IcInputLock,
    iconActive: IcInputLockActive,
    iconError: IcInputLock,
}

const InputPassword = ({ iconInput = iconPassword, inputType, handleValidationUPassword, heightInput, disabled, handleClickEdit, required, ...props }) => {

    const [isShowPwd, setIsShowPwd] = useState(true);
    const [typeInput, setTypeInput] = useState(inputType)
    const [errorInput, setErrorInput] = useState("")
    const [errorBorder, setErrorBorder] = useState(false)
    const [isValue, setIsValue] = useState(false)
    
    const inputEl = useRef(null);

    // handle show click eye
    const _toggleEye = () => {
        setIsShowPwd(pre => !pre)
        isShowPwd ? inputEl.current.type = "text" : inputEl.current.type = "password"
    }

    const _handleFocusInput = () => {
        setErrorInput("")
        setErrorBorder(false)
        setIsValue(true)
        switch (typeInput) {
            case PWD:
                setTypeInput(PWD_ACTIVE)
                break;
            case PWD_ERROR:
                setTypeInput(PWD_ACTIVE)
                break;
            default: return;
        }
    }

    const _handleBlurInput = (e) => {
        const valueText = e.target.value
        if(!valueText){
            setTypeInput(PWD)
            // setIsValue(false)
        }else{
            setTypeInput(PWD_ACTIVE)
            // setIsValue(true)
        }
        const callback = (textErrorInput) => {
            setErrorInput(textErrorInput)
            setErrorBorder(true)
            setTypeInput(PWD_ERROR)
        }
        handleValidationUPassword && handleValidationUPassword(props.value, callback)
    }

    //handle typeInput show icon
    const handleTypeInput = (type) => {
        if (!iconInput) return
        switch (type) {
            case PWD:
                return <iconInput.icon className="icon-left" />
            case PWD_ACTIVE:
                return <iconInput.iconActive className="icon-left" />
            case PWD_ERROR:
                return <iconInput.iconError className="icon-left" />
            default: return;
        }
    }

    useEffect(() => {
        if(disabled === undefined) return;
        inputEl.current?.focus()
    }, [disabled]);

    useEffect(() => {
        if (!props.value) {
            setIsValue(false)
            return
        }
        setTypeInput(PWD_ACTIVE)
        setIsValue(true)
        setErrorInput("")
        setErrorBorder(false)
        if (!errorInput) {
            setTypeInput(PWD_ACTIVE)
        } else {
            setTypeInput(PWD_ERROR)
        }
    }, [props.value, errorInput])

    const iconTypeInput = handleTypeInput(typeInput)
   
    return (
        <InputWrapper heightInput={heightInput}>
            <div className="wrap-input">
                {iconInput &&
                    iconTypeInput
                }
                <input
                    {...props}
                    disabled={disabled}
                    ref={inputEl}
                    onFocus={_handleFocusInput}
                    onBlur={_handleBlurInput}
                    className={`${errorBorder ? "error-input" : ""}` || `${typeInput === "PWD_ACTIVE" ? "active-input" : ""}`}
                />
                {/* {
                    disabled &&
                    <IcEditBlack 
                        className="icon-right"
                        onClick={handleClickEdit}
                    />
                } */}
                {
                    !disabled && (
                        inputEl.current?.type === "text"
                        ? <IcInputEyeActive className="icon-right" onClick={_toggleEye} />
                        : <IcInputEye className="icon-right" onClick={_toggleEye} />
                    )
                }
               {(required && !isValue) && <span className='notice'></span>}
            </div>
                
            {
                errorInput &&
                <div className="error">{errorInput}</div>
            }
        </InputWrapper>
    )
}

export default InputPassword
