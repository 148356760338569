import React from "react";

const IcInputSearchblue = (props) => {
  return (
    <svg
      {...props}
      width={20}
      height={20}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.16658 14.9997C12.3882 14.9997 14.9999 12.388 14.9999 9.16634C14.9999 5.94468 12.3882 3.33301 9.16658 3.33301C5.94492 3.33301 3.33325 5.94468 3.33325 9.16634C3.33325 12.388 5.94492 14.9997 9.16658 14.9997Z"
        stroke="#008C44"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17.5 17.5L13.875 13.875"
        stroke="#008C44"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default IcInputSearchblue
