import styled, { css } from 'styled-components'

const WrapDatePickerInput = styled.div`
    position: relative;
    ${props => props.isError
        ? css`
            .title {
                color: #FF2028!important;
            }

            .wrap-calendar {
                border: 1px solid #FF2028!important;
            }
        `
        : css`
            &.active {
                .wrap-calendar {
                    border: 1px solid #008C44;
                }

                .title {
                    color: #33934E;
                }
            }
        `
    }

    .title {
        position: absolute;
        top: 50%;
        transform: translate(0, -50%);
        left: 44px;
        z-index: 2;
        font-family: "Manrope";
        font-style: normal;
        font-weight: normal;
        font-size: 15px;
        line-height: 20px;
        /* color: #212123; */
        color: #828282;
        transition: top 0.3s, left 0.3s;
        background: #fff;

        &.title-active {
            display: none;
            color: #999999;
            &::after {
                visibility: hidden;
            }
        }

        &.title-require {
            &::after {
                content: '';
                position: absolute;
                width: 3px;
                height: 3px;
                background: #FF2028;
                right: -6px;
                top: 50%;
                transform: translate(0, -50%);
                border-radius: 50%;
            }
        }

        ${props => props.disabled
        ? css`
                background: linear-gradient( 180deg,#FFFFFF 0%,#EDEDED 100%);
                color: #999999;
            `
        : ''
    }
    }

    .wrap-calendar {
        display: flex;
        align-items: center;
        height: 44px;
        border: 0.5px solid #828282;
        box-sizing: border-box;
        border-radius: 8px;
        padding: 7px 10px;

        ${props => props.disabled
        ? css`
                pointer-events: none;
                /* background: linear-gradient( 180deg, #FFFFFF 0%, #EDEDED 100%); */
                background: #EDEDED;
                .agri-input {
                    background: transparent;
                }
            `
        : ''
    }

        .calendar {
            flex-grow: 1;
            height: 100%;

            .react-datepicker {
                font-family: 'Manrope';
                font-weight: 500;
                border: none;
                background: #FFFFFF;
                box-shadow: -5px -5px 40px rgba(0, 0, 0, 0.1), 5px 5px 40px rgba(174, 174, 192, 0.2);
                border-radius: 8px;
            }

            .react-datepicker-wrapper {
                height: 100%;
                width: 100%;
                display: flex;
                align-items: center;
            }

            .react-datepicker__header {
                border: none;
                background: #FFFFFF;
            }

            .react-datepicker-popper {
                z-index: 3;
                transform: translate3d(0px, 36px, 0px) !important;
            }

            .react-datepicker__day-names {
                margin-top: 8px;
                background: #F6F6F6;
                box-sizing: border-box;
                padding: 8px 0 7px;

                & > * {                   
                    font-family: "Manrope";
                    font-style: normal;
                    font-weight: normal;
                    font-size: 11px;
                    line-height: 15px;
                    color: #B7B7B7;

                }
            }
            
            .react-datepicker__day {
                border-radius: 50%;
            }
            
            .react-datepicker__day--keyboard-selected,
            .react-datepicker__day--today {
                background-color: unset;
                color: #000000;
                font-weight: normal;
            }
            
            .react-datepicker__day--selected {
                background-color: #008C44;
                color: #FFFFFF;
            }

            .header-calendar {
                display: flex;
                justify-content: space-between;
                align-items: center;
                .title-header{
                    font-family: "Manrope";
                    font-style: normal;
                    font-weight: 600;
                    font-size: 13px;
                    line-height: 18px;
                    text-align: center;
                    color: #212123;
                }

                button {
                    outline: none;
                    border: none;
                    background: none;
                    cursor: pointer;

                    &:disabled {
                        opacity: 0.4;
                        cursor: default;
                    }
                }

                .reverse {
                    transform: rotate(180deg);
                }
            }

            .agri-input {               
                border: none;
                outline: none;
                font-family: "Manrope";
                font-style: normal;
                font-weight: normal;
                font-size: 15px;
                line-height: 20px;
                color: #212123;
                width: 100%;
                box-sizing: border-box;
                padding: 4px 20px 4px 34px;
            }
        }

        .ic-calendar {
            position: absolute;
            top: 50%;
            left: 15px;
            transform: translate(0, -50%);
            width: 16px;
            height: 16px;
            user-select: none;
            pointer-events: none;
        }
        .ic-arrow-down{
            position: absolute;
            top: 50%;
            right: 8px;
            transform: translate(0, -50%);
            width: 16px;
            height: 16px;
            user-select: none;
            pointer-events: none;
        }

    }

    .err {
        position: absolute;
        top: calc(100% + 4px);
        left: 12px;
        font-size: 10px;
        line-height: 120%;
        font-family: Manrope;
        font-style: normal;
        font-weight: normal;
        color: red;
    }

    ${props => props.extendStyles}
`;

export {
    WrapDatePickerInput
}