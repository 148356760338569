import styled from "styled-components";

const WrapTitlePage = styled.div`
    display: flex;
    width: 100%;
    height: 66px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    background: #FFFFFF;
    .hero-container{
        font-family: "Manrope";
        font-style: normal;
        font-weight: 600;
        font-size: 24px;
        line-height: 33px;
        letter-spacing: -0.01em;
        color: #212123;
        display: flex;
        width: calc(1280px - 148px);
        padding: 0 24px;
        align-items: center;
    }
`

export {
    WrapTitlePage
}