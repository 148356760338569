import React from 'react'

const IcArrowBack = (props) => {
    return (
        <svg {...props} width={18} height={18} viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M11.25 13.5L6.75 9L11.25 4.5" stroke="#010101" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    )
}

export default IcArrowBack