import styled from 'styled-components'

const WrapCheckBox = styled.div`
    display: flex;
    flex-direction: column;
    flex-shrink: 0;
    cursor: pointer;
    .wrap-checkbox{
        display: flex;
        align-items: center;
    }
    .icon{
        padding-right: 5px;
        height: 24px;
        width: 24px;
    }
    p{
        font-family: "Manrope";
        font-style: normal;
        font-weight: normal;
        font-size: 15px;
        line-height: 20px;
        color: #828282;
        white-space: nowrap;
        &.active{
            font-weight: 500;
            color: #212123;
        }
    }
    &.submitting{
        opacity: 0.5;
        pointer-events: none;
    }
    

`

export { WrapCheckBox }