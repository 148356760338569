import styled from 'styled-components'

const WrapHeader = styled.div`
    width: 100%;
    height: 60px;
    background: #008C44;
    .wrap-inner {
        width: 1280px;
        height: 100%;
        padding: 0px 30px;
        box-sizing: border-box;
        align-items: center;
        justify-content: space-between;
        display: flex;

        .logo-header{
            display: flex;
            &:hover{
                cursor: pointer;
            }
        }
        .user{
            display: flex;
            align-items: center;
            position: relative;
            .user_name{
                font-size: 13px;
                line-height: 18px;
                font-family: "Manrope";
                font-style: normal;
                font-weight: normal;
                color: #FFFFFF;
                /* padding-right: 10px; */
                letter-spacing: -0.01em;
            }
            .ic-user{
                margin-left: 12px;
                cursor: pointer;
            }
            .ic-logout{
                margin-left: 10px;
                cursor: pointer;
            }
        }
    }
`;

export {
    WrapHeader
}