import styled, { css, keyframes } from 'styled-components'

const TIME_VISIBILITY = 5000
const TIME_ANIMATION = 1000
const TIME_DELAY_BEFORE_APPEAR = 500
const TIME_DELAY_CLOSE = TIME_VISIBILITY + TIME_ANIMATION + TIME_DELAY_BEFORE_APPEAR

const toastTypes = {
    ERROR: 'ERROR',
    SUCCESS: 'SUCCESS'
}

const moveDown = keyframes`
    0% {
        transform: translate(0, -100%);
    }

    8% {
        transform: translate(0, 80px);
    }

    92% {
        transform: translate(0, 80px);
    }

    100% {
        transform: translate(0, -100%);
    }
`

const configToastError = css`
    border-color: #F9B442;
    background: #FFF2DC;
`
const configToastSuccess = css`
    border-color: #3CC13B;
    background: #EBF9EB;
`

const configToast = {
    [toastTypes.ERROR]: configToastError,
    [toastTypes.SUCCESS]: configToastSuccess
}

const WrapToast = styled.div`
    min-width: 424px;
    width: fit-content;
    margin: 0 auto;
    border: 0.5px solid;
    box-sizing: border-box;
    border-radius: 8px;
    padding: 10px 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    animation: ${moveDown} ${TIME_DELAY_CLOSE}ms ease-in-out forwards;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    animation-delay: ${TIME_DELAY_BEFORE_APPEAR}ms;
    transform: translate(0, -100%);
    
    span {
        margin-left: 4px;
        font-family: Manrope;
        font-style: normal;
        font-weight: normal;
        font-size: 13px;
        line-height: 20px;
        color: #212123;
    }

    ${props => configToast[props.type]}
`;

export {
    TIME_DELAY_CLOSE,
    moveDown,
    toastTypes,
    WrapToast
}