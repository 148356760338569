export const GET_LIST_TRANSACTION = 'GET_LIST_TRANSACTION'
export const GET_LIST_TRANSACTION_ERROR = 'GET_LIST_TRANSACTION_ERROR'
export const CLEAR_LIST_TRANSACTION = 'CLEAR_LIST_TRANSACTION'
export const RESET_CONDFILTER_TRANSACTION = 'RESET_CONDFILTER_TRANSACTION'

export const GET_DETAIL_TRANSACTION = 'GET_DETAIL_TRANSACTION'
export const GET_DETAIL_TRANSACTION_ERROR = 'GET_DETAIL_TRANSACTION_ERROR'
export const CLEAR_DETAIL_TRANSACTION = 'CLEAR_DETAIL_TRANSACTION'

export const COUNT_TRANSACTION = "COUNT_TRANSACTION"
export const COUNT_TRANSACTION_ERROR = "COUNT_TRANSACTION_ERROR"

export const EXPORT_LIST_TRANSACTION_ERROR = "EXPORT_LIST_TRANSACTION_ERROR"