export const GET_LIST_CHECK_POST = "GET_LIST_CHECK_POST"
export const GET_LIST_CHECK_POST_ERROR = "GET_LIST_CHECK_POST_ERROR"
export const CLEAR_LIST_CHECK_POST = "CLEAR_LIST_CHECK_POST"

export const GET_DETAIL_CHECK_POST = "GET_DETAIL_CHECK_POST"
export const GET_DETAIL_CHECK_POST_ERROR = "GET_DETAIL_CHECK_POST_ERROR"
export const CLEAR_DETAIL_CHECK_POST = "CLEAR_DETAIL_CHECK_POST"

export const UPDATE_CHECK_POST_SUCCESS = "UPDATE_CHECK_POST_SUCCESS"
export const UPDATE_CHECK_POST_ERROR = "UPDATE_CHECK_POST_ERROR"

export const RESET_CONDFILTER = "RESET_CONDFILTER"

export const COUNT_LIST_POST_CHECK = "COUNT_LIST_POST_CHECK"
export const COUNT_LIST_POST_CHECK_ERROR = "COUNT_LIST_POST_CHECK_ERROR"

export const EXPORT_LIST_POST_CHECK_ERROR = "EXPORT_LIST_POST_CHECK_ERROR"

export const UPDATE_FACE_POST_CHECK_SUCCESS = "UPDATE_FACE_POST_CHECK_SUCCESS"
export const UPDATE_FACE_POST_CHECK_ERROR = "UPDATE_FACE_POST_CHECK_ERROR"