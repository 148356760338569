import React from "react";

const LogoHeader = (props) => {
  return (
    <svg
      {...props}
      width={105}
      height={28}
      viewBox="0 0 105 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M47.015 21.7909C43.1793 21.7909 40.0921 18.3295 40.0921 14.0573C40.0921 9.78511 43.2105 6.3237 47.015 6.3237C50.8194 6.3237 53.9378 9.78511 53.9378 14.0573C53.9378 18.3295 50.8506 21.7909 47.015 21.7909ZM47.015 1.92676C39.7179 1.92676 33.793 7.38395 33.793 14.0573C33.793 20.7619 39.7179 26.1879 47.015 26.1879C54.312 26.1879 60.237 20.7307 60.237 14.0573C60.237 7.35277 54.312 1.92676 47.015 1.92676Z"
        fill="white"
      />
      <path
        d="M93.7597 6.07444C96.6286 6.07444 97.5953 6.66693 97.5953 8.81863C97.5953 10.6585 96.4727 11.4069 93.8532 11.4069H92.294L90.7036 15.7415H94.0403C96.9092 15.7415 98.4061 16.677 98.4061 18.891C98.4061 21.1675 96.7845 22.0406 93.9156 22.0406H90.0799V6.10562L93.7597 6.07444ZM104.643 18.9222C104.612 18.0179 104.393 17.1759 103.988 16.3963C103.583 15.5855 102.99 14.8995 102.179 14.3382C101.774 14.0263 101.275 13.7769 100.745 13.5898C101.618 13.1532 102.304 12.5919 102.803 11.8747C103.551 10.8144 103.894 9.56704 103.832 8.13258C103.739 6.41746 103.146 5.04537 101.992 4.0163C100.87 3.01841 99.1545 2.48828 96.9404 2.48828H83.9055V25.5956H95.3189C97.3146 25.5956 99.0609 25.3461 100.433 24.8472C101.867 24.3171 102.959 23.5375 103.676 22.5396C104.393 21.5417 104.705 20.3255 104.643 18.9222Z"
        fill="white"
      />
      <path
        d="M81.5044 19.9511C80.0387 21.1361 78.1365 21.8221 76.0783 21.8221C71.4631 21.8221 67.721 18.3295 67.721 14.0573C67.721 9.75393 71.4631 6.29251 76.0783 6.29251C78.1677 6.29251 80.0699 7.00974 81.5044 8.16355V3.45477C79.5709 2.48807 77.3257 1.92676 74.9245 1.92676C67.4716 1.92676 61.4219 7.35277 61.4219 14.0573C61.4219 20.7619 67.4716 26.1879 74.9245 26.1879C77.3257 26.1879 79.5709 25.6266 81.5044 24.6599V19.9511Z"
        fill="white"
      />
      <path
        d="M0.0542182 15.5678C0.424886 18.8753 1.93607 21.8407 4.18859 24.0362C3.93198 23.3519 3.84644 22.639 4.01752 21.9262C4.44521 19.9588 6.46963 18.5617 9.29241 18.1625V8.61067C1.96459 7.3561 -0.401989 12.0037 0.0542182 15.5678Z"
        fill="#E39717"
      />
      <path
        d="M27.9113 12.4322C27.5406 9.12471 26.0294 6.15936 23.7769 3.96387C24.0335 4.64818 24.1191 5.361 23.948 6.07382C23.5203 8.04121 21.4959 9.43835 18.6731 9.83753V19.3894C26.0009 20.6154 28.3675 15.9963 27.9113 12.4322Z"
        fill="#E39717"
      />
      <path
        d="M0.310791 10.8921C2.22116 8.29739 5.64271 6.78621 11.9726 8.55401C23.0641 11.6904 26.6282 4.41963 20.2984 1.4828C18.388 0.513362 16.2495 0.000129692 13.997 0.000129692C7.29646 -0.0283832 1.73644 4.64774 0.310791 10.8921Z"
        fill="#E39717"
      />
      <path
        d="M27.6547 17.1084C25.7443 19.7031 22.3228 21.2143 15.9929 19.4465C4.90138 16.31 1.33726 23.5808 7.66713 26.5177C9.54899 27.4871 11.716 28.0003 13.9685 28.0003C20.6405 28.0289 26.229 23.3527 27.6547 17.1084Z"
        fill="#E39717"
      />
    </svg>
  );
};

export default LogoHeader;
