import React, { useRef } from "react";
import { useDispatch } from "react-redux";

// styles
import { WrapPopupConfirmPostCheck } from "./index.styles";
import { OverlayFullScreen } from "../../mainStyles";

// hook
import { useOnClickOutside } from "../../../hook/useClickOutside";

// images
import IcClose from "../../../assets/images/IcClose";
import IcBtnCancelGreen from "../../../assets/images/IcBtnCancelGreen";
import IcBtnSubmitWhite from "../../../assets/images/IcBtnSubmitWhite";

// component
import Button from "../../control/Button";

// action
import {
  getDetailCheckPost,
  updatePostCheck,
} from "../../../redux/postcheck/action";

const PopupConfirmPostCheck = ({ data, onClickIconClose, payload }) => {
  const ref = useRef(null);

  const dispatch = useDispatch();

  const callbackSuccess = () => {
    dispatch(getDetailCheckPost({id: payload.id}));
  };
  const callbackError = () => {};

  const handleSubmit = () => {
    let configKey = payload.reviewStatus.key;
    dispatch(
      updatePostCheck(
        {
          ...payload,
          reviewStatus: configKey,
        },
        callbackSuccess,
        callbackError
      )
    );
  };

  useOnClickOutside(ref, () => onClickIconClose());

  return (
    <OverlayFullScreen>
      <div className="wrap-inner-screen">
        <WrapPopupConfirmPostCheck ref={ref}>
          <div className="icon-close" onClick={onClickIconClose}>
            <IcClose />
          </div>
          <div className="title">Xác nhận hậu kiểm</div>
          <div className="sub-title">
            Bạn có chắc chắn muốn xác nhận hậu kiểm khách hàng số:{" "}
            <span className="span-bold">{data?.customerId}</span>
          </div>
          <div className="btn-container">
            <div onClick={onClickIconClose}>
              <Button
                className="btn-cancel"
                text="Hủy bỏ"
                iconBtn={<IcBtnCancelGreen />}
              />
            </div>

            <div onClick={onClickIconClose}>
              <Button
                onClick={handleSubmit}
                className="btn-submit"
                text="Đồng ý"
                iconBtn={<IcBtnSubmitWhite />}
              />
            </div>
          </div>
        </WrapPopupConfirmPostCheck>
      </div>
    </OverlayFullScreen>
  );
};

export default PopupConfirmPostCheck;
