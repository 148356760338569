import React from 'react'

const IcCheckedSquareError = (props) => {
    return (
        <svg {...props} width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M8.57514 3.21635L1.51681 14.9997C1.37128 15.2517 1.29428 15.5374 1.29346 15.8284C1.29265 16.1195 1.36805 16.4056 1.51216 16.6585C1.65627 16.9113 1.86408 17.122 2.1149 17.2696C2.36571 17.4171 2.65081 17.4965 2.94181 17.4997H17.0585C17.3495 17.4965 17.6346 17.4171 17.8854 17.2696C18.1362 17.122 18.344 16.9113 18.4881 16.6585C18.6322 16.4056 18.7076 16.1195 18.7068 15.8284C18.706 15.5374 18.629 15.2517 18.4835 14.9997L11.4251 3.21635C11.2766 2.97144 11.0674 2.76895 10.8178 2.62842C10.5682 2.48789 10.2866 2.41406 10.0001 2.41406C9.71369 2.41406 9.43208 2.48789 9.18248 2.62842C8.93287 2.76895 8.7237 2.97144 8.57514 3.21635Z" fill="#F9B442"/>
            <path d="M10 7.5V10.8333" stroke="white" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M10 14.167H10.0083" stroke="white" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>

    )
}

export default IcCheckedSquareError