import React from 'react'

export default function IcPostCheckActive(props) {
  return (
    <svg {...props} width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M23.5 20.9999V18.9999C23.4993 18.1136 23.2044 17.2527 22.6614 16.5522C22.1184 15.8517 21.3581 15.3515 20.5 15.1299" stroke="#275E46" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M17.5 21V19C17.5 17.9391 17.0786 16.9217 16.3284 16.1716C15.5783 15.4214 14.5609 15 13.5 15H5.5C4.43913 15 3.42172 15.4214 2.67157 16.1716C1.92143 16.9217 1.5 17.9391 1.5 19V21" fill="#275E46"/>
        <path d="M17.5 21V19C17.5 17.9391 17.0786 16.9217 16.3284 16.1716C15.5783 15.4214 14.5609 15 13.5 15H5.5C4.43913 15 3.42172 15.4214 2.67157 16.1716C1.92143 16.9217 1.5 17.9391 1.5 19V21" stroke="#275E46" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M16.5 3.12988C17.3604 3.35018 18.123 3.85058 18.6676 4.55219C19.2122 5.2538 19.5078 6.11671 19.5078 7.00488C19.5078 7.89305 19.2122 8.75596 18.6676 9.45757C18.123 10.1592 17.3604 10.6596 16.5 10.8799" stroke="#275E46" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M9.5 11C11.7091 11 13.5 9.20914 13.5 7C13.5 4.79086 11.7091 3 9.5 3C7.29086 3 5.5 4.79086 5.5 7C5.5 9.20914 7.29086 11 9.5 11Z" fill="#275E46" stroke="#275E46" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>

  )
}
