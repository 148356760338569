import * as types from './types'

const initialState = {
    loggedIn: false,
    dataLogin: null,
    error: "",
}

const login = (state = initialState , action) => {
    switch (action.type) {
        case types.LOGIN:
            return {
                ...state,
                loggedIn: true,
                dataLogin: action.data,
                error: ""
            }
        case types.LOGIN_ERROR:
            return{
                ...state,
                loggedIn: false,
                dataLogin: null,
                error: action.error
            }
        case types.LOGOUT:
            return {
                ...state,
                loggedIn: false,
                dataLogin: null,
                error: ''
            }
            
        default:
            return state;
    }

}

export default login