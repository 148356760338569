import React from 'react'


//style
import * as styles from './index.style'
//image
import IcNoData from '../../../assets/images/IcNoData'

const EmptyDataControl = (props) => {
    const { IconNonData, titleDes, labelDes } = props
    return (
        <styles.Bound>
            <div className='animation-container'>
                { 
                    IconNonData?
                    IconNonData
                    :
                    <IcNoData className="ic-no-data" />
                }
                {
                    titleDes &&<h1>{titleDes}</h1>
                }
                <p>{labelDes}</p>
            </div>
        </styles.Bound>
    )
}

export default React.memo(EmptyDataControl)
