import styled from "styled-components";


const WrapInputDropdown = styled.div`
    position: relative;
    .wrap-input{
        height: 44px;
        /* border: 0.5px solid #B7B7B7; */
        border: 0.5px solid #828282;
        box-sizing: border-box;
        border-radius: 8px;  
        display: flex;
        justify-content: space-between;
        padding: 12px 15px 12px 45px;
        align-items: center;
        cursor: pointer;
        width: 100%;
        &.active{
            border: 1px solid #008C44
        }
        .icon-left{
            position: absolute;
            left: 16px;
            top: 12px;
        }
        .placeholder{
            font-family: "Manrope";
            font-size: 13px;
            line-height: 18px;
            letter-spacing: -0.01em;
            color: #828282;
        }
        .notice{
            margin-left: 5px;
        }
        .text-dropdown{
            font-family: "Manrope";
            font-style: normal;
            font-weight: normal;
            font-size: 15px;
            line-height: 20px;
            color: #212123;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }
        .arrow-down{
            cursor: pointer;
            flex-shrink: 0;
        }
        .wrap-dropdown{
            width: 100%;
            z-index: 10;
            top: calc(100% + 2px);
        }
    }
`

export { WrapInputDropdown };