import styled from 'styled-components'

const Bound = styled.div`
    display: grid;
    grid-template-columns: 
        minmax(80px, 116px)
        minmax(100px, 120px) 
        minmax(110px, 150px)
        minmax(150px, 500px) 
        minmax(130px, 180px) 
        // minmax(150px, 1fr)
        141px;
    padding: 0 15px;
    grid-gap: 5px; 
    width: calc(100% - 30px);
    height: 60px;
    background: #FFFFFF;
    box-shadow: inset 0px -0.5px 0px rgba(0, 0, 0, 0.15);
    border-radius: 4px;
    font-family: "Manrope";
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 20px;
    letter-spacing: -0.01em;
    color: #191919;
    align-items: center;
    cursor: pointer;
    &:hover {
        padding: 0 14px;
        height: 58px;
        border: 1px solid #008C44;
    }
    div{
        display: flex;
        flex-direction: row;
        align-items: center;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        display: inline-block;
    }
    .item-transaction-cif{
        color: #008C44;
    }
    .item-transaction-img{
        height: 40px;
        width: 40px;
        border-radius: 50%;
        margin-left: 40px;
        .avatar{
            width: 100%;
            height: 100%;
            border-radius: 50%;
            object-fit: cover;
            
            &:hover {
                cursor: pointer;
            }
        }
    }
    .item-transaction-time{
        font-weight: 600;
    }
    .status{
        border-radius: 8px;
        color: #FFFFFF;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 6px 12px;
        box-sizing: border-box;
        font-weight: 600;
        height: 30px;
        width: 104px;
        &.green{
            background-color: #EBF9EB;
            color: #3CC13B;
            
        }
        &.red{
            background-color: #FFF1EF;
            color: #D62A00;
            
        }
        &.grey{
            background-color: #F6F6F6;
            color: #B7B7B7;
        }
    } 
`;

export {
    Bound
}