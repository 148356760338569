import React from 'react'

const IcCheckedSquare = (props) => {
    return (
        <svg {...props} width={20} height={20} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M15.8333 2.50001H4.16667C3.24619 2.50001 2.5 3.2462 2.5 4.16667V15.8333C2.5 16.7538 3.24619 17.5 4.16667 17.5H15.8333C16.7538 17.5 17.5 16.7538 17.5 15.8333V4.16667C17.5 3.2462 16.7538 2.50001 15.8333 2.50001Z" fill="#3CC13B" />
            <path d="M5.83325 10.8333L8.33325 13.3333L14.1666 6.66667" stroke="white" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    )
}

export default IcCheckedSquare