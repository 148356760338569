import React, { useState, useRef, useEffect, useCallback } from 'react'
import DatePicker from 'react-datepicker'
import moment from 'moment'

// styles
import 'react-datepicker/dist/react-datepicker.css'
import { WrapDatePickerInput } from './index.styles'

// component
import IcCalendar from '../../../assets/images/IcCalendar'
import IcArrowBack from '../../../assets/images/IcArrowBack'
import IcArrowDownBlack from '../../../assets/images/IcArrowDownBlack'
const weekDays = {
    "Sunday": "CN",
    "Monday": "T2",
    "Tuesday": "T3",
    "Wednesday": "T4",
    "Thursday": "T5",
    "Friday": "T6",
    "Saturday": "T7",
}

const DatePickerInput = ({
    title,
    uniqueKey = moment().unix(),
    isRequired = false,
    className,
    defaultValue,
    onChangeDate,
    err,
    extendStyles,
    disabled,
    ...props
}) => {
    const calendar = useRef(null)
    const [date, setDate] = useState(null);
    const [activeLabel, setActiveLabel] = useState(false);
    const [focusInput, setFocusInput] = useState(false);

    useEffect(() => {
        setActiveLabel(!!date)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (!defaultValue)
            onChangeDate(date)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        // if(!defaultValue) return
        if (defaultValue?.toString() === date?.toString()) return;
        setDate(defaultValue)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [defaultValue])

    useEffect(() => {
        if (date) {
            setActiveLabel(true)
            return
        }
        setActiveLabel(false)
    }, [date]);

    const _handleFocus = useCallback(() => {
        setActiveLabel(true)
        setFocusInput(true)
    }, [])

    const _handleBlur = useCallback(() => {
        setFocusInput(false)
        if (!date) {
            setActiveLabel(false)
            return;
        }
    }, [date])

    const _handleClickCalendar = () => {
        if (disabled) return;
    }

    return (
        <WrapDatePickerInput
            onClick={_handleClickCalendar}
            className={
                [
                    className,
                    focusInput ? 'active' : ''
                ].filter(Boolean).join(" ")
            }
            isError={!!err}
            extendStyles={extendStyles}
            disabled={disabled}
        >
            <label
                className={
                    [
                        'title',
                        activeLabel ? 'title-active' : '',
                        isRequired ? 'title-require' : ''
                    ].filter(Boolean).join(" ")
                }
                htmlFor={disabled ? '' : `input-calendar-${uniqueKey}`}
            >
                {title}
            </label>
            <div className="wrap-calendar">
                <div className="calendar" ref={calendar}>
                    <DatePicker
                        selected={date}
                        onChange={(date, e) => {
                            onChangeDate(date)
                            _handleBlur()
                        }}
                        calendarStartDay={1}
                        dateFormat="dd/MM/yyyy"
                        strictParsing={true}
                        onFocus={_handleFocus}
                        onBlur={_handleBlur}
                        className="agri-input"
                        id={`input-calendar-${uniqueKey}`}
                        popperPlacement="bottom-end"
                        showPopperArrow={false}
                        formatWeekDay={formattedDate => weekDays[formattedDate]}
                        renderCustomHeader={({
                            date,
                            changeYear,
                            changeMonth,
                            decreaseMonth,
                            increaseMonth,
                            prevMonthButtonDisabled,
                            nextMonthButtonDisabled,
                        }) => (
                            <div className='header-calendar'>
                                <button
                                    onClick={decreaseMonth}
                                    disabled={prevMonthButtonDisabled}
                                    // className="react-datepicker__navigation react-datepicker__navigation--previous"
                                    aria-label="Previous Month">
                                    <IcArrowBack />
                                </button>
                                <div className="title-header">Tháng {moment(date).format("M/YYYY")}</div>
                                <button
                                    onClick={increaseMonth}
                                    disabled={nextMonthButtonDisabled}
                                    // className="react-datepicker__navigation react-datepicker__navigation--next" 
                                    aria-label="Next Month">
                                    <IcArrowBack className="reverse" />
                                </button>
                            </div>
                        )}
                        {...props}
                    />
                </div>
                <IcCalendar
                    className="ic-calendar"
                // onClick={_handleClickCalendar}
                />
                <IcArrowDownBlack className="ic-arrow-down" />
            </div>
            <div className="err">{err}</div>
        </WrapDatePickerInput>
    )
}

export default DatePickerInput
