import styled from 'styled-components'

const WrapLoading = styled.div`
    position: fixed;
    top: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.6);
    .wrap-overlay {
        max-width: 1280px;

        .wrap-notify {
            margin-top: 80px;
            min-width: 424px;
            width: 424px;
            height: 40px;
            margin: 80px auto 0;
            border: 0.5px solid #1B51E5;
            box-sizing: border-box;
            border-radius: 8px;
            padding: 10px 0;
            display: flex;
            align-items: center;
            justify-content: center;
            background: #EFF2FE;
            box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    
            span {
                margin-left: 4px;
                font-family: Manrope;
                font-style: normal;
                font-weight: normal;
                font-size: 13px;
                line-height: 20px;
                color: #212123;
            }
        }
    }
`;

export {
    WrapLoading
}