import styled from 'styled-components'

const WrapPopupPostCheck = styled.div`
    width: 352px;
    padding: 24px;
    background:#FFFFFF;
    // position: relative;
    box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    position: absolute;
    top: 341px;
    left: 839px;
    p{
        font-family: "Manrope";
        font-style: normal;
        font-weight: normal;
        font-size: 15px;
        line-height: 20px;
        color: #828282;
    }
    .title{
        font-family: "Manrope";
        font-style: normal;
        font-weight: 600;
        font-size: 20px;
        line-height: 27px;
        margin-bottom: 12px;
        color: #212123;
        letter-spacing: -0.02em;
    }
    .sub-title{
        font-family: "Manrope";
        font-style: normal;
        font-weight: normal;
        font-size: 13px;
        line-height: 20px;
        color: #828282;
        margin-bottom: 26px;
    }
    .icon-close{
        top: 24px;
        right: 24px;
        position: absolute;
        width: 24px;
        height: 24px;
        cursor: pointer;
    }
    .reason-list{
        .wrap-checkbox{
            position: relative;
            font-family: Manrope;
            font-style: normal;
            font-weight: normal;
            font-size: 15px;
            line-height: 20px;
            color: #828282;
            margin-bottom: 18px;
        }
        .reason-text{
            /* display: none; */
            position: relative;
            font-family: Manrope;
            font-style: normal;
            font-weight: normal;
            font-size: 15px;
            line-height: 20px;
            margin: 9px 0 8px 0;
            padding: 12px 48px 25px 19px;
            border: 0.5px solid #828282;
            box-sizing: border-box;
            border-radius: 8px;
            textarea{
                font-family: Manrope;
                font-style: normal;
                font-weight: normal;
                font-size: 15px;
                line-height: 20px;
                border: none;
                width: 285px;
                outline: unset;
                resize: none;
                &::placeholder{
                    color: #B7B7B7;
                }
            }
            .required{
                width: 3px;
                height: 3px;
                border-radius: 50%;
                position: absolute;
                top: 40%;
                left: 88%;
                background-color: #D62A00;
            }
        }
        .reason-notice{
            padding-left: 12px;
            margin-bottom: 24px;
            color: #D62A00;
            font-family: Manrope;
            font-style: normal;
            font-weight: normal;
            font-size: 13px;
            line-height: 20px;
        }
    }
    .btn-submit{
        width: 100%;
        height: 38px;
        margin-top: 30px;
        background: #008C44;
        color: #FFFFFF;
        font-family: Manrope;
        font-style: normal;
        font-weight: 600;
        font-size: 13px;
        line-height: 18px;
    }
`;

export {
    WrapPopupPostCheck
}