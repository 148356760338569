import React, { useEffect, useState, useRef } from 'react'




//images
import IcArrowDownBlack from '../../../assets/images/IcArrowDownBlack'
import { useOnClickOutside } from '../../../hook/useClickOutside'

//component
import DropdownList from '../DropdownList'

//style
import { WrapInputDropdown } from './index.styles'

const SELECT = "SELECT"
const SELECT_ACTIVE = "SELECT_ACTIVE"
const SELECT_ERROR = "SELECT_ERROR"

const InputDropdown = ({ 
    className,
    iconInput, 
    getValueDropdown, 
    placeholder, 
    dataFilterOption, 
    objChoice, 
    maxItemShow=5,
    required,
    handleScrollEnd=()=>{} 
}) => {
    const [isShowValueDropdown, setIsShowValueDropdown] = useState(false)
    const [typeInput, setTypeInput] = useState(SELECT)
    const [valueDropdown, setValueDropdown] = useState(objChoice)
    const [borderActive, setBorderActive] = useState(false)
    const refOption = useRef(null)

    useEffect(() => {
        if(!isShowValueDropdown && !valueDropdown) {
            setTypeInput(SELECT)
            setBorderActive(false)
            return;
        }

        if(!isShowValueDropdown && valueDropdown) {
            setTypeInput(SELECT_ACTIVE)
            setBorderActive(false)
            return;
        }

        if(isShowValueDropdown) {
            setTypeInput(SELECT_ACTIVE)
            setBorderActive(true)
            return;
        }
    }, [isShowValueDropdown, valueDropdown])

    useEffect(() => {
        setValueDropdown(objChoice)
        return () => {

        }
    }, [objChoice])
    

    //handle typeInput show icon
     const handleTypeInput = (type) => {
        if (!iconInput) return
        switch (type) {
            case SELECT:
                return <iconInput.icon className="icon-left" />
            case SELECT_ACTIVE:
                return <iconInput.iconActive className="icon-left" />
            case SELECT_ERROR:
                return <iconInput.iconError className="icon-left" />
            default: return;
        }
    }
    const iconTypeInput = handleTypeInput(typeInput)

    const _handleSetValueSelect = (val) => {
        setIsShowValueDropdown(!isShowValueDropdown)
        setValueDropdown(val)
        getValueDropdown && getValueDropdown(val)
    }

    const _handleShowValueDropdown = () =>{
        setIsShowValueDropdown(!isShowValueDropdown)
    }
     
    const textDropdown = valueDropdown ? valueDropdown.text : placeholder
    const classTextDropdown = valueDropdown ? "text-dropdown" : "placeholder"
    
    useOnClickOutside(refOption, () => setIsShowValueDropdown(false))

    return (
        <WrapInputDropdown ref={refOption} className={className}>
            <div className={`wrap-input${borderActive ? " active" : ""}`} onClick={_handleShowValueDropdown}>
                {iconInput &&
                        iconTypeInput
                }
                {/* <span className={classTextDropdown} title={valueDropdown?.text}>{textDropdown}</span> */}
                <p className={classTextDropdown} title={valueDropdown?.text}>
                    {textDropdown}
                    {(required) && (!valueDropdown) ?  <span className='notice'>*</span> : ''}
                </p>
                <IcArrowDownBlack className="arrow-down"/>
                {
                    isShowValueDropdown &&
                    <DropdownList
                        isShowCheckStatus={{isShow: true, isSingleChoice: true, isCheckMark:true}}
                        className="wrap-dropdown"
                        listItem={dataFilterOption}
                        valueSelected={valueDropdown}
                        setValueSelect={_handleSetValueSelect}
                        maxItemShow={maxItemShow}
                        handleScrollEnd={handleScrollEnd}
                    />
                }
            </div>
        </WrapInputDropdown>
    )
}

export default InputDropdown
