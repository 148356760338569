import React, { useEffect, useReducer, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { sha256 } from 'js-sha256';

// styles
import { WrapLogin } from './index.styles'

//component
import Input from '../control/Input';
import InputPassword from '../control/InputPassword';
import Button from '../control/Button';
import { MAIN } from '../../constant/path';


//images
import LogoLogin from '../../assets/images/LogoLogin'
import IcInputUser from '../../assets/images/IcInputUser';
import BackgroundLogin from '../../assets/images/bg_login.png'
import IcButtonLogin from '../../assets/images/IcButtonLogin';
import IcInputUserActive from '../../assets/images/IcInputUserActive';

//action
import { loginAction } from '../../redux/login/action';
import { toHaveValue } from '@testing-library/jest-dom/dist/matchers';


const iconUser = {
    icon: IcInputUser,
    iconActive: IcInputUserActive,
    iconError: IcInputUser,
}

const Login = () => {

    const navigate = useNavigate();
    const dispatch = useDispatch()
    
    const [textErrorForm,setTextErrorForm] = useState("")
    const [stateForm, setStateForm] = useReducer(
        (state, action) => ({ ...state, ...action }),
        {
            username: "",
            password: "",
        }
    )

    const { loggedIn } = useSelector(state => ({
        loggedIn: state.login.loggedIn
    }))

    // useEffect(() => {
    //   document.addEventListener("onautocomplete", _handleOnAutoComplete)
    
    //   return () => {
    //     document.removeEventListener("onautocomplete", _handleOnAutoComplete)
    //   }
    // }, [])

    // const _handleOnAutoComplete = (e) => {
    //     e.target.hasAttribute('autocompleted'); // true or false
    //     // e.preventDefault(); // prevent autocomplete
    //     console.log(e.target)
    //     console.log(e.target.value)
    //     let inputName = document.getElementById("username")
    //     console.log(inputName.value)
    //     // handleChangeValue(e)
    // }
    

    useEffect(() => {
        if(!loggedIn) return;
        navigate(`/${MAIN}`)

    }, [loggedIn]);

    const handleChangeValue = (event) => {
        const { name, value } = event.target;
        setStateForm({
            [name]: value
        })
        setTextErrorForm("")
    }

    const _handleValidationUser = (value, callback) =>{
        if (!value?.trim() || 0 === value.length) {
            callback("Tài khoản không được để trống")
            return
        }
        if (value.indexOf(' ') >= 0) {
            callback("Tài khoản không chứa khoảng trắng")
            return
        }
    }

    const _handleValidationUPassword = (value, callback) =>{
        if(!value?.trim() || 0 === value.length){
            callback("Mật khẩu không được để trống")
        }
    }

    const SubmitFormSuccess = () =>{
        setTextErrorForm("")
    }

    const SubmitFormError = () =>{
        if(!stateForm.username || !stateForm.password){
            setTextErrorForm("Tài khoản hoặc Password không được để trống")
        }else{
            setTextErrorForm("Sai thông tin tài khoản")
        }
    }

    const submitForm = (e) => {
        const dataLogin = {
            username: stateForm.username,
            password: sha256(stateForm.password),
        };
        dispatch(loginAction(navigate, dataLogin, SubmitFormSuccess, SubmitFormError))
    }

    return (
        <WrapLogin>
            <div className="wrap-inner-login">
                <div className="login-form">
                    <LogoLogin className="icon-logo"/>
                    <p className="title">Chào mừng bạn đến với OCB Portal</p>
                    <p className="note">Vui lòng nhập thông tin tài khoản của bạn</p>
                    <form className="form" autoComplete="off">
                        {/* <input type="text"
                        autoComplete="off"
                            id="username"
                            placeholder="Tên đăng nhập"
                            name="username"
                            required={true}
                            value={stateForm.username} 
                            onChange={handleChangeValue}
                        /> */}
                        <Input
                            autoComplete="username"
                            id="username"
                            placeholder="Tên đăng nhập"
                            inputType="TEXT"
                            name="username"
                            required={true}
                            value={stateForm.username}
                            iconInput={iconUser}
                            onChange={handleChangeValue}
                            handleValidationInput={_handleValidationUser}
                        />
                        <InputPassword
                            autoComplete="new-password"
                            id="password"
                            placeholder="Mật khẩu"
                            type="password"
                            inputType="PWD"
                            value={stateForm.password}
                            name="password"
                            required={true}
                            onChange={handleChangeValue}
                            onKeyPress={(e) => {
                                if(e.key === "Enter"){
                                    submitForm()
                                }
                            }}
                            handleValidationUPassword={_handleValidationUPassword}
                        />
                        <div className="error">{textErrorForm}</div>
                    </form>
                    <Button 
                            onClick={submitForm}
                            text="Đăng nhập"
                            iconBtn={<IcButtonLogin />}
                            className="btn-login"
                        />
                </div>
                <div className="bg_login">
                    <img src={BackgroundLogin} alt="bg_login" />
                </div>
            </div>
        </WrapLogin>
    )
}

export default Login
