import styled, { css } from 'styled-components'

const HEIGHT_ITEM = 42;

const WrapDropdownList = styled.div`
    position: absolute;
    top: calc(100% + ${props => props.alignTop || "18px"});
    left: 0;
    /* left: 50%; */
    /* transform: translate(-50%, 0); */
    background: #FFFFFF;
    box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    border: 0.5px solid #B7B7B7;
    box-sizing: border-box;

    & > * {
        display: flex;
        /* justify-content: space-between; */
        align-items: center;
        box-sizing: border-box;
        padding: 12px 16px;
        /* height: ${HEIGHT_ITEM}px;     */
        box-shadow: inset 0px -1px 0px rgba(146, 168, 189, 0.25);
        &:first-child{
            border-radius: 8px 8px 0 0;
        }
        &:last-child{
            border-radius: 0 0 8px 8px;
            box-shadow: inset 0px -1px 0px #EEEEF1;
        }
        span {
            display: block;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            font-family: "Manrope";
            font-style: normal;
            font-weight: normal;
            font-size: 13px;
            line-height: 20px;
            color: #212123;
        }

        .ic-checkbox{
            margin-right: 6px;
        }

        .ic-check-mark{
            /* position: absolute;
            right: 10px; */
        }

        &:hover { 
            cursor: pointer;
        }
    }
    .item-checked{
        background-color: #EBF9EB;
        display: flex;
        justify-content: space-between;
        align-items: center;        
    }
`;

export {
    WrapDropdownList
}