import React from "react";

const IcSmartOTP = (props) => {
  return (
    <svg
      {...props}
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="16" cy="16" r="16" fill="#E3F9EE" />
      <path
        d="M10.375 12.25C11.4105 12.25 12.25 11.4105 12.25 10.375C12.25 9.33947 11.4105 8.5 10.375 8.5C9.33947 8.5 8.5 9.33947 8.5 10.375C8.5 11.4105 9.33947 12.25 10.375 12.25Z"
        stroke="#008C44"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.375 17.875C11.4105 17.875 12.25 17.0355 12.25 16C12.25 14.9645 11.4105 14.125 10.375 14.125C9.33947 14.125 8.5 14.9645 8.5 16C8.5 17.0355 9.33947 17.875 10.375 17.875Z"
        stroke="#008C44"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16 12.25C17.0355 12.25 17.875 11.4105 17.875 10.375C17.875 9.33947 17.0355 8.5 16 8.5C14.9645 8.5 14.125 9.33947 14.125 10.375C14.125 11.4105 14.9645 12.25 16 12.25Z"
        stroke="#008C44"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16 17.875C17.0355 17.875 17.875 17.0355 17.875 16C17.875 14.9645 17.0355 14.125 16 14.125C14.9645 14.125 14.125 14.9645 14.125 16C14.125 17.0355 14.9645 17.875 16 17.875Z"
        stroke="#008C44"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16 23.5C17.0355 23.5 17.875 22.6605 17.875 21.625C17.875 20.5895 17.0355 19.75 16 19.75C14.9645 19.75 14.125 20.5895 14.125 21.625C14.125 22.6605 14.9645 23.5 16 23.5Z"
        stroke="#008C44"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M21.625 12.25C22.6605 12.25 23.5 11.4105 23.5 10.375C23.5 9.33947 22.6605 8.5 21.625 8.5C20.5895 8.5 19.75 9.33947 19.75 10.375C19.75 11.4105 20.5895 12.25 21.625 12.25Z"
        stroke="#008C44"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M21.625 17.875C22.6605 17.875 23.5 17.0355 23.5 16C23.5 14.9645 22.6605 14.125 21.625 14.125C20.5895 14.125 19.75 14.9645 19.75 16C19.75 17.0355 20.5895 17.875 21.625 17.875Z"
        stroke="#008C44"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default IcSmartOTP;
