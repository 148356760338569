import React, { useEffect, useState } from 'react'

//style
import { InputWrapper } from './index.styles'

const TEXT = "TEXT"
const TEXT_ACTIVE = "TEXT_ACTIVE"
const TEXT_ERROR = "TEXT_ERROR"

const Input = ({ iconInput, inputType, handleValidationInput, className = "", heightInput, error, required, ...props }) => {
    const [typeInput, setTypeInput] = useState(inputType)
    const [errorInput, setErrorInput] = useState("")
    const [errorBorder, setErrorBorder] = useState(false)
    const [isValue, setIsValue] = useState(false)

    useEffect(() => {
        if (!props.value) {
            setIsValue(false)
            setTypeInput(TEXT)
            return
        }
        setTypeInput(TEXT_ACTIVE)
        setIsValue(true)
        setErrorInput("")
        setErrorBorder(false)
        if (!errorInput) {
            setTypeInput(TEXT_ACTIVE)
        } else {
            setTypeInput(TEXT_ERROR)
        }
    }, [props.value, errorInput])

    useEffect(() => {
        setErrorInput(error)
    }, [error]);

    // useEffect(() => {
    //     if(!props.disabled) return
    //         setTypeInput(TEXT)
    // }, [props.disabled])

    const _handleFocusInput = () => {
        setErrorInput("")
        setErrorBorder(false)
        setIsValue(true)
        switch (typeInput) {
            case TEXT:
                setTypeInput(TEXT_ACTIVE)
                break;
            case TEXT_ERROR:
                setTypeInput(TEXT_ACTIVE)
                break;
            default: return;
        }
    }

    const _handleBlurInput = (e) => {
        const valueText = e.target.value
        if (!valueText) {
            setTypeInput(TEXT)
            // setIsValue(false)
        } else {
            setTypeInput(TEXT_ACTIVE)
            // setIsValue(true)
        }
        const callback = (textErrorInput) => {
            setErrorInput(textErrorInput)
            setErrorBorder(true)
            setTypeInput(TEXT_ERROR)
        }
        handleValidationInput && handleValidationInput(props.value, callback)
    }

    //handle typeInput show icon
    const handleTypeInput = (type) => {
        if (!iconInput) return
        switch (type) {
            case TEXT:
                return <iconInput.icon className="icon-left" />
            case TEXT_ACTIVE:
                return <iconInput.iconActive className="icon-left" />
            case TEXT_ERROR:
                return <iconInput.iconError className="icon-left" />
            default: return;
        }
    }
    const iconTypeInput = handleTypeInput(typeInput)
    
    return (
        <InputWrapper heightInput={heightInput} className={className}>
            <div className="wrap-input">
                {iconInput &&
                    iconTypeInput
                }
                <input
                    {...props}
                    onFocus={_handleFocusInput}
                    onBlur={_handleBlurInput}
                    className={`${errorBorder || errorInput ? "error-input" : ""}` || `${typeInput === "TEXT_ACTIVE" ? "active-input" : ""}`}
                />
                {(required && !isValue) && <span className='notice'></span>}
            </div>
            {
                errorInput &&
                <div className="error">{errorInput}</div>
            }
        </InputWrapper>
    )
}

export default Input
